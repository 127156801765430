<template>
  <echarts-com v-loading="loading" id="parkingDensity-paymentPeriod" :options="options"/>
</template>

<script>
import * as echarts from "echarts";

export default {
  components:{
    echartsCom:()=>import("@/components/echartsCom")
  },
  data() {
    return {
      options:{},
      loading:false
    }
  },
  methods:{
    async getOptions(params={},dateType){
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/pay/time/point", params);
        if (res.code === 0) {
          let xAxis_data = []
          let values = []

          for(let item of res.data){
            xAxis_data.push(item.timePoint)
            values.push({
              value:item.payAmount,
              paymentNum:item.paymentNum,
            })
          }


          this.$set(this, "options", {
            tooltip: {
              trigger: "axis",
              extraCssText: "box-shadow: 0px 0px 13px rgba(0, 193, 153, 0.22);",
              padding: [15, 10],
              formatter: (params) => {
                return `<div>
                  <p>
                    <span style="display: inline-block;width:10px;height:10px;border-radius: 50%;background: linear-gradient(360deg, #F9C253 0%, #FFBB00 100%);"></span>
                    <span>支付笔数:</span>
                    <span>${params[0].data.paymentNum}</span>
                  </p>
                  <p>
                    <span style="display: inline-block;width:10px;height:10px;border-radius: 50%;background: linear-gradient(360deg, #12D5CE 0%, #12D5CE 0%, #2EB6CF 98%, #2EB6CF 100%);"></span>
                    <span>支付金额:</span>
                    <span>${params[0].data.value} 元</span>
                  </p>
                </div>`;
              },
            },
            xAxis: {
              type: "category",
              data: xAxis_data,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: "#2a4d69",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#2b4a6e",
                  type: "dashed",
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#2a4d69",
                },
              },
              axisLabel: {
                color: "white",
              },
            },
            grid: {
              left: "0%",
              width: "100%",
              height: "90%",
              bottom: "0%",
              containLabel: true, // 包含坐标轴中的文字
            },
            series: [
              {
                type: "bar",
                barMaxWidth: 25,
                itemStyle: {
                  barBorderRadius: [33, 33, 0, 0],
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {offset: 0, color: "#FFBF5A"},
                    {offset: 1, color: "rgba(255,191,90,0.4)"},
                  ]),
                },
                data: values,
              },
            ],
          });
        }else{
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch {
        this.$message.error("车位数量统计获取失败");
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss">
#parkingDensity-paymentPeriod {

}
</style>
